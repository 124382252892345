.chocolat-zoomable.chocolat-zoomed {
    cursor: zoom-out;
}
.chocolat-open {
    overflow: hidden;
}
.chocolat-overlay {
    transition: opacity 0.4s ease, visibility 0s 0.4s ease;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
}
.chocolat-overlay.chocolat-visible {
    transition: opacity 0.4s, visibility 0s;
    visibility: visible;
    opacity: 0.8;
}

.chocolat-wrapper {
    transition: opacity 0.4s ease, visibility 0s 0.4s ease;
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: 16;
    color: #fff;
    visibility: hidden;
}
.chocolat-wrapper.chocolat-visible {
    transition: opacity 0.4s, visibility 0s;
    opacity: 1;
    visibility: visible;
}

.chocolat-zoomable .chocolat-img {
    cursor: zoom-in;
}
.chocolat-loader {
    transition: opacity 0.3s;
    height: 32px;
    width: 32px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -16px;
    margin-top: -16px;
    z-index: 11;
    opacity: 0;
}
.chocolat-loader.chocolat-visible {
    opacity: 1;
}

.chocolat-image-wrapper {
    position: fixed;
    width: 0px;
    height: 0px;
    left: 50%;
    top: 50%;
    z-index: 14;
    text-align: left;
    transform: translate(-50%, -50%);
}

.chocolat-image-wrapper .chocolat-img {
    position: absolute;
    width: 100%;
    height: 100%;
}
.chocolat-wrapper .chocolat-left {
    width: 50px;
    height: 100px;
    cursor: pointer;
    z-index: 17;
    visibility: hidden;
}

.chocolat-layout {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.chocolat-image-canvas {
    transition: opacity .2s;
    opacity: 0;
    flex-grow: 1;
    align-self: stretch;
}
.chocolat-image-canvas.chocolat-visible {
    opacity: 1;
}
.chocolat-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.chocolat-wrapper .chocolat-right {
    width: 50px;
    height: 100px;
    cursor: pointer;
    z-index: 17;
    visibility: hidden;
}
.chocolat-wrapper .chocolat-right.active {
    visibility: visible;
}
.chocolat-wrapper .chocolat-left.active {
    visibility: visible;
}
.chocolat-wrapper .chocolat-top {
    height: 50px;
    overflow: hidden;
    z-index: 17;
    flex-shrink: 0;
}
.chocolat-wrapper .chocolat-close {
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}
.chocolat-wrapper .chocolat-bottom {
    height: 40px;
    font-size: 15px;
    z-index: 17;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(0, 0, 0, 0.58);
    flex-shrink: 0;
    display: flex;
    align-items: center;

}
.chocolat-wrapper .chocolat-set-title {
    display: inline-block;
    padding-right: 15px;
    line-height: 1;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.chocolat-wrapper .chocolat-pagination {
    float: right;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 15px;
    /*border-right: 1px solid rgba(255, 255, 255, 0.2);*/
}
.chocolat-wrapper .chocolat-fullscreen {
    width: 16px;
    height: 40px;
    display: block;
    cursor: pointer;
    float: right;
}
.chocolat-wrapper .chocolat-description {
    display: inline-block;
    flex-grow: 1;
    text-align: left;
}

/* no container mode*/
body.chocolat-open > .chocolat-overlay {
    z-index: 15;
}
body.chocolat-open > .chocolat-loader {
    z-index: 15;
}
body.chocolat-open > .chocolat-image-wrapper {
    z-index: 17;
}

 @media only screen and (max-width: 600px), screen and (max-height: 550px) {
    .chocolat-wrapper .chocolat-bottom {
        font-size: 11px;
    }
}

/* container mode*/
.chocolat-in-container .chocolat-wrapper,
.chocolat-in-container .chocolat-image-wrapper,
.chocolat-in-container .chocolat-overlay {
    position: absolute;
}
.chocolat-in-container {
    position: relative;
}

.chocolat-zoomable.chocolat-zooming-in .chocolat-image-wrapper,
.chocolat-zoomable.chocolat-zooming-out .chocolat-image-wrapper {
    transition: width .2s ease, height .2s ease;
}
.chocolat-zoomable.chocolat-zooming-in .chocolat-img,
.chocolat-zoomable.chocolat-zooming-out .chocolat-img {
    transition: margin .2s ease;
}

/* uncomment to hide controls when zoomed-in*/
/*
.chocolat-zoomable .chocolat-top,
.chocolat-zoomable .chocolat-bottom,
.chocolat-zoomable .chocolat-right,
.chocolat-zoomable .chocolat-left {
    transition: opacity .3s ease, visibility 0s .3s;
   opacity: 1;
}

.chocolat-zoomable.chocolat-zoomed .chocolat-top,
.chocolat-zoomable.chocolat-zoomed .chocolat-bottom,
.chocolat-zoomable.chocolat-zoomed .chocolat-right,
.chocolat-zoomable.chocolat-zoomed .chocolat-left {
    visibility: hidden;
    opacity: 0;
}
*/
