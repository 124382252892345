@import 'assets/css/chocolat.css';

html,body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.About {
  transform: none !important;
}

.parallax {
  background-image: url('/Columbus.jpg');
  height: 85vh;
  background-attachment: fixed;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(0px, -20px);
}

.content {
  height: 350px;
  background-color: #8a9684;
}

.text {
  position: absolute;
  z-index: 1;
  margin-top: -105.5px;
  width: 100%;
  text-align: center;
  font-size: 40px;
}

.about {
  color: #f2e6cf;
  text-transform: uppercase;
  letter-spacing: 50px;
  text-indent: 50px;
}

  #map {
    width: 100%;
    height: 100%;
  }

  @font-face {
    font-family: 'Futura';
    font-weight: bold;
    src: local('Futura Std'), url('./assets/fonts/FuturaPTBold.otf'), format('opentype');
  }

  @font-face {
    font-family: 'Futura PT Bold Italic';
    font-weight: bold;
    font-style: italic;
    src: local('FuturaPTBoldOblique'), url('./assets/fonts/FuturaPTBoldOblique.otf'), format('opentype');
  }

  @font-face {
    font-family: 'Futura PT';
    font-weight: lighter;
    src: local('Futura PT Light'), url('./assets/fonts/FuturaPTLight.otf') format('opentype'),
                                    url('./assets/fonts/FuturaPTLight.woff2') format("woff2"),
                                   url('./assets/fonts/FuturaPTLight.woff') format("woff"),
                                   url('./assets/fonts/FuturaPTLight.ttf') format("truetype"),
                                   url('./assets/fonts/FuturaPTLight.svg') format("svg"),
                                   url('./assets/fonts/FuturaPTLight.eot') format("embedded-opentype");
  }

  @font-face {
    font-family: 'Futura PT Demi Italic';
    font-weight: bold;
    font-style: italic;
    src: local('Futura PT Demi Italic'), url('./assets/fonts/FuturaPTDemiOblique.otf'), format('opentype');
  }


  h1 {
    font-family: Futura;
  }

  .about-paragraph p {
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: none;
    width: 500px;
    margin: auto;
    text-align: center;
    font-family: 'Futura PT', 'Futura';
    font-weight: lighter;
    text-indent: 0;
  }

  .main-title {
    position: absolute;
    padding: 10px;
    text-transform: uppercase;
    color: #50463f;
    right: 0;
    left: 0;
    text-align: center;
    font-size: 52px;
    letter-spacing: .07em;
    z-index: 4;
  }

  .footer {
    height: 100px;
    background-color: #f2e6cf;
    padding: 0;
    margin: 0;
  }

  .footer h1 {
    position: absolute;
    text-transform: uppercase;
    color: #50463f;
    text-align: center;
    width: 100%;
    font-family: 'Futura PT', 'Futura';
    letter-spacing: 5px;
    font-size: 25px;
  }

  .wrapper {
    height: 100%;
    width: 100%;
    display: grid;
  }

  .wrapper .wrapper__video{
    object-fit: cover;
    width: 57%;
    height: 100vh;
  }

  .lookout_tower,
  .covered_bridge,
  .the_inn {
  	width: 66% !important;
  }

  .page-content {
    text-align: center;
    color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: 43%;
    height: 100vh;
    background-color: #8a9684;
    opacity: 1.9;
  }

  .page-content p {
    height: 50%;
    font-family: 'Futura PT', 'Futura';
    top: 15%;
    font-size: large;
  }

  .content-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .page-heading {
    font-family: 'Futura PT Demi Italic', 'Futura';
  }

  .gallery {
    background-color: #50463f;
    text-align: center;
    display: grid;
  }

  .gallery h1 {
    text-transform: uppercase;
    color: #f2e6cf;
    text-align: center;
    font-family: 'Futura PT', 'Futura';
    letter-spacing: 5px;
    font-size: 25px;
    margin: 10px;
  }

  .gallery img {
    margin: auto;
    padding: 25px;
    height: 200px;
    width: 260px;
    object-fit: cover;

  }

  h3  {
    font-family: futura;
    bottom: 0;
    position: absolute;
    padding: 5px;
    text-align: right;
    font-size: 1em;
    color: #50463f;
  }

  h3:first-child {
    left: -50%;
  }

  h3:last-child {
    right: 0%;
  }

  .socials {
    text-align: center;
    font-family: futura;
    position: relative;
    top: 50%;
    margin-top: -10.5px;
  }

  .page-wrapper {
    content: "";
    display: inline-block;
    clear: both;
  }

  .wrapper__video {
    float: left;
  }

  .about-video {
    width: 57%;
    margin: auto;
  }
  .page-wrapper {
    background-color: #50463f;
  }

  .poster {
    width: 57%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  a:link, a:visited {
    text-decoration: none;
    color: #50463f;
  }

  .h3-long {
  	width: 50%;
  	text-align: initial;
  }

  @media only screen and (max-device-width) {
    .parallax {
      background-attachment: scroll;
    }
  }

  @media only screen and (max-width: 600px), screen and (max-height: 550px) {
    .about {
      letter-spacing: 29px;
    }
    .about-paragraph p {
      width: auto;
      padding: 10px;
    }
    .about h1 {
      margin-bottom: 10px;
    }
    .content {
      height: 265px;
    }
    .page-wrapper {
      width: 100%;
    }
    .page-content {
      top: 100vh;
      width: 100%;
      min-width: 100%;
      height: 250px;
    }
    .page-content p {
      font-size: small;
      font-weight: lighter;
    }
    .wrapper .wrapper__video {
      width: 100%;
    }
    .content-wrapper {
      width: 90%;
    }
    .gallery {
      margin-top: 250px;
    }
    h3 {
      position: initial;
      margin: 0;
      font-size: small;
    }
    .about-video {
      width: 100% !important;
      margin: auto;
    }

    .lookout_tower,
    .covered_bridge {
    	width: 100% !important;
    }
    .poster {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    .gallery img {
    	padding: 0;
    	padding-bottom: 10px;
    	width: 100%;
    }
    .main-title {
    	font-size: 30px;
    }
    .page-heading {
    	font-size: 16px;
    }
  }



 .about-section {
    margin: 0;
    padding: 0;
    color: #f2e6ce;
    height: 300px;
    background-color: #f2e6cf;
  }


  .about-section img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 10% 75%;
    z-index: 0;

  }

  .about-link h1 {
    left: 50%;
    padding-top: 150px;
    text-align: center;
    position: absolute;
    letter-spacing: 25px;
    margin: 0;
    text-transform: uppercase;
    z-index: 1;
    transform: translate(-50%, 0%);
    color: #f2e6ce;
  }

  .about-function {
    display: none;
  }

  .image-container {
    width: 50%;
    margin: auto;
  }

   @media only screen and (max-width: 900px) {
    .page-content p {
      font-size: 11px;
    }
      h3:last-child {
    	bottom: 15px;
    	right: initial;
    	width: initial;
    	text-align: end
  }
}

  @media only screen and (max-width: 1200px) {
    h3 {
      font-size: 10px;
    }
    .content-wrapper {
    	width: 75%;
    }
  }

  @media only screen and (min-width: 300px) {
  	.main-title {
  		text-align: center;
  		letter-spacing: 0;
  	}
  }
